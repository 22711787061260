export default {
  siteTitle: 'Get bonus',
  blogerName: 'RAIA',
  socialsList: [
    {
      name: 'youtube',
      url: 'https://www.youtube.com/canaldoraia'
    },
    {
      name: 'telegram',
      url: 'https://t.me/+0HJG7-vlLl4xNTFh'
    },
    {
      name: 'twitch',
      url: 'https://www.twitch.tv/raia'
    },
    {
      name: 'discord',
      url: 'https://discord.com/invite/raia'
    }
  ],
  projects: [
    {
      name: 'monro',
      url: 'https://monrocasino.life/c10de6c8e',
      gameTitle: 'Burning Chilli X (BGaming)',
      casinoBonus1: '<strong>150%</strong><div>No primeiro depósito</div>',
    },
    {
      name: 'drip',
      url: 'https://dripcasino.life/cfa76fd9b',
      gameTitle: 'Alien Fruits (BGaming)',
      casinoBonus1: '<strong>150%</strong><div>No primeiro depósito</div>',
    },
    {
      name: 'starda',
      url: 'https://stardacasino.life/c045fce3b',
      gameTitle: 'Starda Queen (BGaming)',
    },
    {
      name: 'izzi',
      url: 'https://izzicasino.life/c9e3da4c0',
      gameTitle: 'Izzi Art (BGaming)',
    },
    {
      name: 'legzo',
      url: 'https://legzocasino.life/ce3b1fbfd',
      gameTitle: 'Legzo Punk (BGaming)',
    },
    {
      name: 'sol',
      url: 'https://solcasino.life/ccaeb429b',
      gameTitle: 'Doors Of Sol (BGaming)',
    },
    {
      name: 'jet',
      url: 'https://jetcasino.life/c9866744a',
      gameTitle: 'Candy Monsta (BGaming)',
    },
    {
      name: 'fresh',
      url: 'https://fresh-azhqmrpiks.com/c3e5f46c6',
      gameTitle: 'Doors Of Fresh (BGaming)',
    }
  ],
  headerText: 'Cadastre-se com um código promocional <strong>RAIA</strong> e receba 50 rodadas grátis nos jogos abaixo',
  yourPromocode: 'Seu código promocional',
  gameBonus: 'Bónus no jogo',
  promocode: 'RAIA',
  casinoBonus1: '<strong>100%</strong><div>No primeiro depósito</div>',
  casinoBonus2: '<strong>50FS</strong><div>Para cadastro com código promocional</div>',
  firstDeposit: 'DE PRIMEIRO DEPÓSITO',
  bonusesText: 'GIROS GRATIS',
  copyMessage: 'Código promocional copiado',
  theme: 'brazil'
};
